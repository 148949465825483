import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: window.env.FIREBASE_API_KEY,
  authDomain: window.env.FIREBASE_AUTH_DOMAIN,
  projectId: window.env.FIREBASE_PROJECT_ID,
};

let app;

export function initFirebase() {
  if (
    !firebaseConfig.apiKey ||
    firebaseConfig.apiKey === '%REACT_APP_FIREBASE_API_KEY%'
  ) {
    console.error('Required FIREBASE_API_KEY is missing! See README.md');
  }
  if (app === undefined) {
    app = initializeApp(firebaseConfig);
  }
}
