import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuth,
  onIdTokenChanged,
  signInWithPopup,
  signInWithRedirect,
  signOut as signOutFirebase,
  GoogleAuthProvider,
} from 'firebase/auth';

import { setCredentials, setError } from '../redux/slices/auth';
import { backendApi } from '../services/api';
import { AUTH_METHOD_GOOGLE, AUTH_METHOD_PASSWORD } from '../constants/auth';

let auth;

export function initAuth() {
  auth = getAuth();
  // Set the language to the default device/browser preference.
  // This affects email action templates and OAuth view localization.
  // See: https://firebase.google.com/docs/reference/node/firebase.auth.Auth#languagecode
  auth.useDeviceLanguage();
}

// Hook that listens to auth state changes.
// auth.user === undefined : authentication in progress
// auth.user === null      : not authenticated
// auth.user               : user is authenticated
export function useListenAuth() {
  const dispatch = useDispatch();
  useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      // Firebase auth throws error when running Jest tests
      return;
    }
    const unsubscribe = onIdTokenChanged(
      auth,
      async (user) => {
        if (user) {
          const token = await user.getIdToken();
          const newUser = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
          };
          dispatch(
            setCredentials({
              user: newUser,
              token,
              method: AUTH_METHOD_GOOGLE,
            }),
          );
        } else {
          dispatch(
            setCredentials({ user, token: null, method: AUTH_METHOD_GOOGLE }),
          );
        }
      },
      (err) => {
        console.error(err);
        dispatch(setError({ code: err.code, message: err.message }));
      },
    );
    return unsubscribe;
  }, [dispatch]);
}

// Returns hook that immediately signs out.
// Returns true when sign out is complete.
export function useSignOut() {
  const dispatch = useDispatch();
  const [signedOut, setSignedOut] = useState(false);
  const method = useSelector((state) => state.auth.method);
  useEffect(() => {
    const start = async () => {
      const finalize = () => {
        // reset api state in redux immediately, clearing cached entries
        dispatch(backendApi.util.resetApiState());
        setSignedOut(true);
      };

      // signing out logic depends on authentication method
      if (method === AUTH_METHOD_GOOGLE) {
        try {
          await signOutFirebase(auth);
          finalize();
        } catch (err) {
          console.error(err);
        }
      } else if (method === AUTH_METHOD_PASSWORD) {
        // sign out when using password authentication method
        dispatch(setCredentials({ user: null, token: null, method }));
        finalize();
      }
    };
    start();
    // we want to run this function only once so that's why
    // dependencies array is empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return signedOut;
}

// https://firebase.google.com/docs/auth/web/redirect-best-practices

// Returns true if popup sign-in for Google should be used.
// When developing in localhost the authentication with redirect
// can have problems so it's better to use popup.
const shouldUsePopup = () => {
  // this can be overridden by query parameter
  const param = new URLSearchParams(window.location.search).get('popup');
  if (param !== null) {
    return param === '1';
  }
  return process.env.NODE_ENV === 'development';
};

export async function googleSignIn() {
  const provider = new GoogleAuthProvider();

  // scopes that we want to request from Google API
  provider.addScope('email');
  // profile scope for display name and photo
  provider.addScope('profile');

  if (shouldUsePopup()) {
    try {
      await signInWithPopup(auth, provider);
    } catch (err) {
      console.error(err);
    }
  } else {
    signInWithRedirect(auth, provider);
  }
}
