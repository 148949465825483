import { AUTH_METHOD_GOOGLE } from '../constants/auth';

const storage = sessionStorage;

const KEY = 'redux_state';

let prevAuth;

export function readPersistedState() {
  try {
    const stateStr = storage.getItem(KEY);
    const state = stateStr ? JSON.parse(stateStr) : {};
    // save previous values
    prevAuth = state.auth;
    return state;
  } catch (err) {
    console.error(err);
    return {};
  }
}

// middleware for persisting state into storage
export const persistState = (store) => (next) => (action) => {
  const state = store.getState();
  if (state.auth !== prevAuth) {
    // Auth changed so persist state into storage, unless we are using
    // Google authentication. It persists its authentication in its
    // own way.
    if (state.auth.method !== AUTH_METHOD_GOOGLE) {
      storage.setItem(KEY, JSON.stringify({ auth: state.auth }));
    }
    prevAuth = state.auth;
  }
  return next(action);
};
