import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import './i18n';

import { initFirebase } from './services/firebase';
import { initAuth } from './services/auth';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';

// Hyper theme
import './assets/scss/Saas.scss';

initFirebase();
initAuth();

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
