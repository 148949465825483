import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'auth',
  initialState: { token: null },
  reducers: {
    setCredentials: (state, { payload: { user, token, method } }) => {
      if (state.method && state.method !== method && !user) {
        // ignore this action
        // for example google auth can set user=null when
        // password authentication is still valid
        return state;
      }
      state.user = user;
      state.token = token;
      state.method = method;
      delete state.error;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const { setCredentials, setError } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
