import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import AccountLayout from './AccountLayout';

export default function Enroll() {
  const { t } = useTranslation();
  const email = useSelector((state) => state.auth?.user?.email);
  const text = t(
    'Account {{email}} is not registered in Talopalvelu. Please contact the customer service.',
    { email: email ?? '<?>' },
  );
  return (
    <AccountLayout bottomLinks={null}>
      <div className="text-center w-75 m-auto">
        <h4 className="text-dark-50 text-center mt-0 fw-bold">
          {t('Account not found')}
        </h4>
        <p className="text-muted mt-3 mb-4">{text}</p>
      </div>

      <div className="mb-3 mb-0 text-center">
        <Link to="/account/logout" className="btn btn-primary">
          {t('Log Out')}
        </Link>
      </div>
    </AccountLayout>
  );
}
