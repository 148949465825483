import React from 'react';
import { Navigate } from 'react-router-dom';

import { useSignOut } from '../../services/auth';

const Logout = () => {
  const signedOut = useSignOut();
  return signedOut ? <Navigate to="/account/login" /> : null;
};

export default Logout;
